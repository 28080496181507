import { Link } from "react-router-dom";

import makeAppointment from "../../assets/images/homecare-2.jpg";

export default function Welcome() {
  return (
    <>
      <div className="col-12 col-md-6">
        <h4 className="fw-bold">Hello & Welcome to</h4>
        <h1 className="fw-bold">Supreme Health Services LLC</h1>
        <p>
          Experience compassionate and professional care right at the comfort of
          you or your loved ones home, with our homecare services. We
          acknowledge the importance of caring for our clients, helping them
          maintain safety and independence wherever they call home. Being a
          high-standard Home Care in Chicagoland Area, we follow
          industry-leading practices to provide excellent and quality life to
          our clients.
        </p>
        <div className="cta-btn">
          <form className="d-flex">
            <Link
              className="btn btn-lg shs-btn px-5"
              type="submit"
              to="/contact-us"
            >
              Make an Appointment
            </Link>
          </form>
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
        <img
          className="img-fluid"
          src={makeAppointment}
          alt="Making aan appointment"
        />
      </div>
    </>
  );
}
