import "../../assets/styles/App.css";
import shsllc from "../../assets/images/shsllc.png";

import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="App-header">
      {/* <nav className="navbar navbar-expand-lg d-none">
        <div className="container">
          <a href="./" className="navbar-brand">
            <img
              className="img-fluid"
              alt="Supreme Health Services LLC"
              style={{ width: "150px" }}
              src={shsllc}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="./">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="./about-us">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="./services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="./staffing">
                  Staffing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="./consultation">
                  Consultation
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="./">
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="./careers">
                  Careers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="./contact-us">
                  Contact Us
                </a>
              </li>
            </ul>
            <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline-success" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </nav> */}
      <nav className="navbar navbar-expand-lg">
        <div className="container align-items-center">
          <Link to="/" className="navbar-brand">
            <img
              className="img-fluid"
              alt="Supreme Health Services LLC"
              style={{ width: "150px" }}
              src={shsllc}
            />
          </Link>
          <span
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <i className="fa-solid fa-bars-staggered"></i>
          </span>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
            style={{ width: "85vw", backgroundColor: "whitesmoke" }}
          >
            <div className="offcanvas-header pb-0">
              <h5
                className="offcanvas-title text-center"
                id="offcanvasRightLabel"
              >
                Menu
              </h5>
              <span
                type="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <i className="fas fa-xmark btn-lg"></i>
              </span>
            </div>
            <div className="offcanvas-body align-items-center">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about-us" className="nav-link">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/services"
                    className="nav-link"
                    aria-disabled="true"
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/staffing"
                    className="nav-link"
                    aria-disabled="true"
                  >
                    Staffing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/consultation"
                    className="nav-link"
                    aria-disabled="true"
                  >
                    Consultation
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="http://www.supremehealthservicesllc.com/blog"
                    className="nav-link"
                    aria-disabled="true"
                  >
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/careers" className="nav-link" aria-disabled="true">
                    Careers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contact-us"
                    className="nav-link"
                    aria-disabled="true"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              <div className="cta-btn">
                <form className="d-flex">
                  <a
                    className="btn shs-btn mx-auto px-5"
                    href="tel:+12244545529"
                  >
                    <i className="fa-solid fa-phone"></i>&nbsp;
                    <span className="d-none d-md-inline d-lg-inline d-xl-inline d-xxl-inline">
                      +1 (224) 454-5529
                    </span>
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
