import { Link } from "react-router-dom";
import "./AboutUs.css";

import homecareWoman from "../../assets/images/home-care-women.png";
import homecareBlanket from "../../assets/images/home-care-blanket.jpg";
import nurseOldManWheelchair from "../../assets/images/nurse-old-man-wheelchair.jpg";

export default function AboutUs() {
  return (
    <div className="about-us container py-5">
      <div className="row mb-5 align-items-center">
        <div className="col-12 col-md-6 order-md-2 d-flex justify-content-center align-items-center">
          <img
            src={homecareWoman}
            className="img-fluid"
            alt="nurse-old-man-wheelchair"
          />
        </div>
        <div className="col-12 col-md-6 order-md-1">
          <h1 className="fw-bold">About us</h1>
          <h2 className="fw-bolder">Home Care Service In Chicagoland Area</h2>
          <p>
            At Supreme Health Services LLC, we provide exceptional care to our
            clients, identifying all their physical, mental, and emotional
            needs. Providing graceful care is our passion.
          </p>
          <p>
            Our goal is to professionally care for every life compassionately
            and with grace. We offer personal and nursing care services within
            the comfort of your own home. Our services include both medical and
            non-medical care which are available to people of all ages on a
            basis of hourly care up to 24-hours a day, daily visit, and monthly
            visits.
          </p>
        </div>
      </div>

      <div className="row mb-5 pt-5 align-items-center">
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <img
            src={homecareBlanket}
            className="img-fluid"
            alt="home-care-blanket"
          />
        </div>
        <div className="col-12 col-md-6">
          <h2 className="fw-bolder">
            Supreme Health Services LLC is a provider of Home Care services
          </h2>
          <p>
            In Chicago Land Area and is privileged to serve individuals and
            families in Cook, Dupage, and Mc Henry. To guarantee that clients
            receive only high-quality home care services, we treat every life
            with compassion, skillfulness, and grace. We offer homecare services
            that are comprehensive and flexible according to our clients’ unique
            needs, preferences, and requests.
          </p>
        </div>
      </div>

      <div className="row pt-5 align-items-center">
        <div className="col-12 col-md-6 order-md-2 d-flex justify-content-center align-items-center">
          <img
            src={nurseOldManWheelchair}
            className="img-fluid"
            alt="nurse=woamn-care"
          />
        </div>
        <div className="col-12 col-md-6 order-md-1">
          <h2 className="fw-bolder">Our Values</h2>
          <p>
            Our mission and vision will be achieved through the application of
            our core values, which include:
          </p>
          <ul className="list-unstyled">
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp;Keeping our
              clients health, quality of life and well-being central in the
              design and delivery of services.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp;Valuing,
              supporting, recognizing and appreciating our staff who are our
              greatest asset.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp;Treating and
              interacting with our clients with respect, dignity, compassion,
              empathy, honesty, and integrity while recognizing and maintaining
              confidentially of client information.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp; Nurturing a
              work environment that encourages personal enjoyment and enhances
              job satisfaction and performance through recognition and reward.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp;Adhering to the
              professional code of ethics of the Home Care Industry.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp;Showing respect
              for all cultures, religions, ethnicities, sexual orientation,
              ages, gender and disabilities.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp; Developing and
              maintaining positive relationships with the community, including
              local Home Care and Health care personnel/organizations.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp;Recruiting,
              training and retaining competent staff.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp;Conducting our
              business in an accountable and responsible manner.
            </li>
            <li className="list-item">
              <i className="fa-solid fa-circle-check"></i>&nbsp;Applying
              continuous quality improvement measure throughout our Agency.
            </li>
          </ul>
          <p>For more information about us,</p>
          <p>
            <Link to="/contact-us" className="btn shs-btn">
              Reach Us Today
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
