import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
} from "antd";

const { TextArea } = Input;
const { Option } = Select;

const CaregiverForm = () => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log({ values });
  };

  return (
    <>
      <div className="container my-5" id="form">
        <h1 className="text-center my-5 text-decoration-underline">
          CAREGIVER EMPLOYMENT APPLICATION
        </h1>
        <div className="p-3 border border-dark mb-3">
          <p>
            <strong>INSTRUCTIONS:</strong>&nbsp;If you need help filling out
            this application form or for any phase of the employment process,
            please notify the person who gave you this form and every reasonable
            effort will be made to meet your needs in a reasonable amount of
            time.
          </p>
          <ul>
            <li>Please read "Applicant Note" below.</li>
            <li>Complete all pages of this application</li>
            <li>
              Print clearly. Incomplete or illegible applications may not be
              accepted
            </li>
            <li>
              If more space is needed to complete any question, use comment
              section below
            </li>
            <li>Appliation will be valid for 60 days</li>
          </ul>
        </div>
        <div>
          <p>
            <strong>APPLICANT NOTE:</strong>&nbsp;This application form is
            intended for use in evaluating your qualifications for employment
            with Supreme Health Services LLC. This is not an employment
            contract. Please answer all appropriate questions completely and
            accurately. False or misleading statements during the interview and
            on this form are grounds for terminating the application process or,
            if discovered after employment begins, terminating employment. All
            qualified applicants will receive consideration and will be treated
            throughout their employment without regard to race, color, religion,
            sex, national origin, age, disability, or any other protected class
            status under applicable law. Additional testing for the presence of
            illegal drugs in your body may be required prior to employment.
          </p>
        </div>
        <div
          style={{
            height: 10 + "px",
            backgroundColor: "#000000",
            border: "none",
          }}
          className="my-3"
        ></div>
        <Form
          form={form}
          name="caregiver-form"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <h3 className="text-decoration-underline mt-5 mb-3">
            PERSONAL INFORMATION
          </h3>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Today's date"
                name="todaysDate"
                rules={[
                  {
                    required: true,
                    message: "Today's date is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="MMM DD, YYYY"
                  // defaultValue={today}
                  // disabled
                />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Position(s) applied for"
                name="psitionsAppliedFor"
                rules={[
                  {
                    required: true,
                    message: "Position(s) applied for is required",
                  },
                ]}
              >
                <Input placeholder="Enter the position(s) you are applying for" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Last name is required",
                  },
                ]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First name is required",
                  },
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Middle name"
                name="middleName"
                rules={[
                  {
                    required: true,
                    message: "Middle name is required",
                  },
                ]}
              >
                <Input placeholder="Middle name" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Date of Birth"
                name="dateOfBirth"
                rules={[
                  {
                    required: true,
                    message: "Date of Birth is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="MMM DD, YYYY"
                  placeholder="Select your date of birth"
                  // defaultValue={today}
                  // disabled
                />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Gender is required",
                  },
                ]}
              >
                <Input placeholder="Gender" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Social Security Number"
                name="ssn"
                rules={[
                  {
                    required: true,
                    message: "Social Security Number is required",
                  },
                ]}
              >
                <Input placeholder="Social Security Number" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Current Address"
                name="currentAddress"
                rules={[
                  {
                    required: true,
                    message: "Current Address is required",
                  },
                ]}
              >
                <Input placeholder="Current Address" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Previous Address"
                name="previousAddress"
                rules={[
                  {
                    required: true,
                    message: "Previous Address is required",
                  },
                ]}
              >
                <Input placeholder="Previous Address" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Home Phone"
                name="homePhone"
                rules={[
                  {
                    required: true,
                    message: "Home Phone is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Home Phone" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Work Phone"
                name="workPhone"
                rules={[
                  {
                    required: true,
                    message: "Work Phone is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Work Phone" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Cell Phone"
                name="cellPhone"
                rules={[
                  {
                    required: true,
                    message: "Cell Phone is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Cell Phone" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Alternate Phone"
                name="alternatePhone"
                rules={[
                  {
                    required: true,
                    message: "Alternate Phone is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Alternate Phone" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Emergency Contact Name 1"
                name="emergencyContactName1"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Name 1 is required",
                  },
                ]}
              >
                <Input placeholder="Emergency Contact Name 1" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Emergency Contact Phone 1"
                name="emergencyContactPhone1"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Phone 1 is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Emergency Contact Phone 1" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Emergency Contact Name 2"
                name="emergencyContactName2"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Name 2 is required",
                  },
                ]}
              >
                <Input placeholder="Emergency Contact Name 2" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Emergency Contact Phone 2"
                name="emergencyContactPhone2"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Phone 2 is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Emergency Contact Phone 2" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Valid Driver's License Number"
                name="validLicenseDriverNumber"
                rules={[
                  {
                    required: true,
                    message: "Valid Driver's License Number is required",
                  },
                ]}
              >
                <Input placeholder="Valid Driver's License Number" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="State Issued"
                name="stateIssued"
                rules={[
                  {
                    required: true,
                    message: "State Issued is required",
                  },
                ]}
              >
                <Input placeholder="State Issued" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Expiration Date"
                name="expirationDate"
                rules={[
                  {
                    required: true,
                    message: "Expiration Date is required",
                  },
                ]}
              >
                <Input placeholder="Expiration Date" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Make & Model of Vehicle"
                name="makeAndModelOfVehicle"
                rules={[
                  {
                    required: true,
                    message: "Make & Model of Vehicle is required",
                  },
                ]}
              >
                <Input placeholder="Make & Model of Vehicle" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Year of Vehicle"
                name="stateIssued"
                rules={[
                  {
                    required: true,
                    message: "Year of Vehicle is required",
                  },
                ]}
              >
                <Input placeholder="Year of Vehicle" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Auto in Co."
                name="autoInCo"
                rules={[
                  {
                    required: true,
                    message: "Auto in Co. is required",
                  },
                ]}
              >
                <Input placeholder="Auto in Co." />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Policy Number"
                name="policyNumber"
                rules={[
                  {
                    required: true,
                    message: "Policy Number is required",
                  },
                ]}
              >
                <Input placeholder="Policy Number" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Expiration Date"
                name="expirationDate"
                rules={[
                  {
                    required: true,
                    message: "Expiration Date is required",
                  },
                ]}
              >
                <Input placeholder="Expiration Date" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Have you ever submitted and application here before? Yes / No? If yes, when?"
                name="previousApplication"
                rules={[
                  {
                    required: true,
                    message: "Previous application is required",
                  },
                ]}
              >
                <Input placeholder="Previous application" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Have you ever been exmployed here before? Yes / No? If yes, when?"
                name="previousEmployment"
                rules={[
                  {
                    required: true,
                    message: "Previous employment field is required",
                  },
                ]}
              >
                <Input placeholder="Previous employment here?" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <Form.Item
              label="What do you like (or think you would like) most about working with older adults?"
              name="likeMost"
            >
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item
              label="What do you like (or think you would like) least about working with older adults?"
              name="likeLeast"
            >
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item
              label="What personal rewards do you get from working with seniors?"
              name="personalRewards"
            >
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item
              label="Are you a Certified Nursing Assistant?"
              name="certifiedNursingAssistant"
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <h1>Education</h1>
          <div className="row education">
            <Form.Item
              label="Highest Grade Completed"
              name="highestGradeCompleted"
            >
              <Radio.Group>
                <Radio value="highSchool">High School</Radio>
                <Radio value="someCollege">Some College</Radio>
                <Radio value="collegeUniversity">College/University</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="col-12">
              <Form.List name="education">
                {(fields, { add, remove }) => (
                  <>
                    <div key="default" style={{ display: "flex", gap: "10px" }}>
                      <Form.Item
                        label="School Type"
                        name={["education", 0, "schoolType"]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="School Name"
                        name={["education", 0, "schoolName"]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="City, State"
                        name={["education", 0, "cityState"]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Major/Subject"
                        name={["education", 0, "major"]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="# Yrs Attended"
                        name={["education", 0, "yearsAttended"]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Graduate"
                        name={["education", 0, "graduate"]}
                      >
                        <Radio.Group>
                          <Radio value="yes">Yes</Radio>
                          <Radio value="no">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    {fields.map(({ key, name, fieldKey }, index) => (
                      // if (index === 0) return null;
                      // return (
                      <div key={key} style={{ display: "flex", gap: "10px" }}>
                        <Form.Item
                          label="School Type"
                          name={[name, "schoolType"]}
                          fieldKey={[fieldKey, "schoolType"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="School Name"
                          name={[name, "schoolName"]}
                          fieldKey={[fieldKey, "schoolName"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="City, State"
                          name={[name, "cityState"]}
                          fieldKey={[fieldKey, "cityState"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Major/Subject"
                          name={[name, "major"]}
                          fieldKey={[fieldKey, "major"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="# Yrs Attended"
                          name={[name, "yearsAttended"]}
                          fieldKey={[fieldKey, "yearsAttended"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Graduate"
                          name={[name, "graduate"]}
                          fieldKey={[fieldKey, "graduate"]}
                        >
                          <Radio.Group>
                            <Radio value="yes">Yes</Radio>
                            <Radio value="no">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Button onClick={() => remove(name)} danger>
                          Remove
                        </Button>
                      </div>
                      // );
                      // })}
                    ))}
                    <Button type="primary" onClick={() => add()}>
                      Add Education
                    </Button>
                  </>
                )}
              </Form.List>
            </div>
            <p>
              *For employment, our minimum education requirement is either a GED
              or High School diploma.
            </p>
          </div>

          <h1>WORK HISTORY</h1>
          <p className="mb-4">
            Your application will not be considered unless all questions in this
            section are answered. Since we will make every effort to contact
            previous employers, the correct telephone numbers of past employers
            are essential.
          </p>
          <h3>
            <em>MOST RECENT EMPLOYER</em>
          </h3>
          <div className="row work-history">
            <Form.Item
              label="Are you currently working for this employer?"
              name="currentlyWorking"
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Company Name" name="companyName">
              <Input />
            </Form.Item>
            <Form.Item label="City" name="companyCity">
              <Input />
            </Form.Item>
            <Form.Item label="State" name="companyState">
              <Input />
            </Form.Item>
            <Form.Item label="Phone Number" name="companyPhoneNumber">
              <Input />
            </Form.Item>
            <Form.Item label="Dates Employed" name="datesEmployed">
              <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item label="Job Title" name="jobTitle">
              <Input />
            </Form.Item>
            <Form.Item label="Supervisor's Name" name="supervisorName">
              <Input />
            </Form.Item>
            <Form.Item label="Duties" name="duties">
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item label="Salary" name="salary">
              <Input addonBefore="$" addonAfter="per hour/week/month" />
            </Form.Item>
            <Form.Item label="Reason for Leaving" name="reasonForLeaving">
              <Input />
            </Form.Item>

            {/* Second Most Recent Employer Section */}
            <Form.Item label="Second Most Recent Employer" />

            <Form.Item label="Company Name" name="secondCompanyName">
              <Input />
            </Form.Item>

            <Form.Item label="City" name="secondCompanyCity">
              <Input />
            </Form.Item>

            <Form.Item label="State" name="secondCompanyState">
              <Input />
            </Form.Item>

            <Form.Item label="Phone Number" name="secondCompanyPhone">
              <Input />
            </Form.Item>

            <Form.Item
              label="Dates Employed From"
              name="secondDatesEmployedFrom"
            >
              <DatePicker />
            </Form.Item>

            <Form.Item label="To" name="secondDatesEmployedTo">
              <DatePicker />
            </Form.Item>

            <Form.Item label="Job Title" name="secondJobTitle">
              <Input />
            </Form.Item>

            <Form.Item label="Supervisor's Name" name="secondSupervisorName">
              <Input />
            </Form.Item>

            <Form.Item label="Duties" name="secondDuties">
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item label="Salary" name="secondSalary">
              <InputNumber />
            </Form.Item>

            <Form.Item label="Reason for Leaving" name="secondReasonForLeaving">
              <Input />
            </Form.Item>

            {/* Third Most Recent Employer Section */}
            <Form.Item label="Third Most Recent Employer" />

            <Form.Item label="Company Name" name="thirdCompanyName">
              <Input />
            </Form.Item>

            <Form.Item label="City" name="thirdCompanyCity">
              <Input />
            </Form.Item>

            <Form.Item label="State" name="thirdCompanyState">
              <Input />
            </Form.Item>

            <Form.Item label="Phone Number" name="thirdCompanyPhone">
              <Input />
            </Form.Item>

            <Form.Item
              label="Dates Employed From"
              name="thirdDatesEmployedFrom"
            >
              <DatePicker />
            </Form.Item>

            <Form.Item label="To" name="thirdDatesEmployedTo">
              <DatePicker />
            </Form.Item>

            <Form.Item label="Job Title" name="thirdJobTitle">
              <Input />
            </Form.Item>

            <Form.Item label="Supervisor's Name" name="thirdSupervisorName">
              <Input />
            </Form.Item>

            <Form.Item label="Duties" name="thirdDuties">
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item label="Salary" name="thirdSalary">
              <InputNumber />
            </Form.Item>

            <Form.Item label="Reason for Leaving" name="thirdReasonForLeaving">
              <Input />
            </Form.Item>
          </div>

          <h1>SECURITY</h1>
          <div className="row security">
            {/* Security Section */}
            <Form.Item label="Security" />

            <Form.Item
              label="Are you at least 19 years of age?"
              name="ageConfirmation"
            >
              <Checkbox>Yes</Checkbox>
            </Form.Item>

            <Form.Item
              label="List states and counties of residence for the past seven years"
              name="residenceHistory"
            >
              <Input.TextArea rows={2} />
            </Form.Item>

            <Form.Item
              label="Have you had any moving traffic violations?"
              name="trafficViolations"
            >
              <Checkbox.Group>
                <Checkbox value="yes">Yes</Checkbox>
                <Checkbox value="no">No</Checkbox>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label="If yes, please describe"
              name="trafficViolationDetails"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Have you been charged/convicted of a felony and/or misdemeanor or served time?"
              name="criminalRecord"
            >
              <Checkbox.Group>
                <Checkbox value="yes">Yes</Checkbox>
                <Checkbox value="no">No</Checkbox>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              label="If yes, please describe"
              name="criminalRecordDetails"
            >
              <Input.TextArea rows={2} />
            </Form.Item>

            <Form.Item
              label="Have you ever been a charged perpetrator or appeared on any child abuse registry in the last 5 years?"
              name="childAbuseRegistry"
            >
              <Checkbox.Group>
                <Checkbox value="yes">Yes</Checkbox>
                <Checkbox value="no">No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>

          <h1>AVAILABILITY</h1>
          <div className="row availability">
            <Form.Item label="Available to begin work" name="availabilityDate">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Availability">
              <Checkbox.Group>
                <Checkbox value="mornings">Mornings</Checkbox>
                <Checkbox value="afternoon">Afternoon</Checkbox>
                <Checkbox value="overnights">Overnights</Checkbox>
                <Checkbox value="liveIn">Live In</Checkbox>
                <Checkbox value="weekdays">Weekdays</Checkbox>
                <Checkbox value="weekends">Weekends</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <p>
              Please indicate the days of the week as well as the earliest and
              latest times that you are available for work
            </p>
            <Form.Item>
              <Checkbox.Group>
                <Checkbox value="monday">Monday</Checkbox>
                <Checkbox value="tuesday">Tuesday</Checkbox>
                <Checkbox value="wednesday">Wednesday</Checkbox>
                <Checkbox value="thursday">Thursday</Checkbox>
                <Checkbox value="friday">Friday</Checkbox>
                <Checkbox value="saturday">Saturday</Checkbox>
                <Checkbox value="sunday">Sunday</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label="Time of day (From - To)">
              <Input placeholder="From - To" />
            </Form.Item>
          </div>

          <h1>PREFERENCES</h1>
          <div className="row preferences">
            <Form.Item label="Areas willing to work">
              <Checkbox.Group>
                <Checkbox value="kaneCounty">Kane County</Checkbox>
                <Checkbox value="cookCounty">Cook County</Checkbox>
                <Checkbox value="duPageCounty">DuPage County</Checkbox>
                <Checkbox value="willCounty">Will County</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label="Services you are willing to provide">
              <Checkbox.Group>
                <Checkbox value="companionship">Companionship</Checkbox>
                <Checkbox value="housekeeping">
                  Housekeeping (dust/vacuum)
                </Checkbox>
                <Checkbox value="errands">
                  Errands/Shopping/Transportation
                </Checkbox>
                <Checkbox value="personalCare">Personal Care</Checkbox>
                <Checkbox value="mealPreparation">Meal Preparation</Checkbox>
                <Checkbox value="laundry">Laundry/Ironing</Checkbox>
                <Checkbox value="activities">
                  Activities (games/crafts)
                </Checkbox>
                <Checkbox value="medicationReminders">
                  Medication Reminders
                </Checkbox>
                <Checkbox value="dementia">Dementia/Alzheimer's Care</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              label="Are you willing to provide service to a client with a pet (e.g. Cats or Dogs)?"
              name="petService"
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Are you willing to provide service to a client that smokes?"
              name="smokerService"
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <h1>JOB RELATED SKILLS</h1>
          <div className="row job-related-skills">
            <Form.Item label="Describe any training or life skills you have that apply to caring for a senior:">
              <TextArea />
            </Form.Item>
            <Form.Item label="Describe any work history you havr that would apply to caring for a senior:">
              <TextArea />
            </Form.Item>
          </div>

          <h1>REFERENCES</h1>
          <div className="row references">
            {/* References Section */}
            <Form.Item label="References (Do not include relatives)" />

            {[1, 2, 3].map((i) => (
              <div key={i}>
                <Form.Item label={`Full Name ${i}`} name={`referenceName${i}`}>
                  <Input />
                </Form.Item>

                <Form.Item
                  label={`Phone Number ${i}`}
                  name={`referencePhone${i}`}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Best Time of Day to Call"
                  name={`referenceBestTime${i}`}
                >
                  <Select>
                    <Option value="am">AM</Option>
                    <Option value="pm">PM</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Relationship"
                  name={`referenceRelationship${i}`}
                >
                  <Input />
                </Form.Item>
              </div>
            ))}
          </div>

          <h3 className="text-decoration-underline mt-5 mb-3">
            CERTIFICATION AND RELEASE
          </h3>

          <div className="row my-3">
            <div className="col">
              <p>
                I certify that I have read and understood the applicant note at
                the top of this form and that the answers given by me to the
                foregoing questions and the statements made by me are complete
                and true to the best of my knowledge and belief. I understand
                that any false information, omissions or misrepresentations of
                facts in this application may result in rejection of my
                application or discharge at any time during my employment. I
                authorize the company and/or its agents, including
                consumer-reporting bureaus, to verify any of these information
                including, but not limited to, criminal history and motor
                vehicle driving records. I authorize all persons, schools,
                companies and law enforcement authorities to release any
                information concerning my background and hereby release any said
                persons, schools, companies and law enforcement authorities from
                any liability for any damage whatsoever for issuing these
                information. I release this company from any liability which
                might result from making such investigations. I also understand
                that the use of illegal drugs is prohibited during employment. I
                am willing to submit to drug testing to detect the use of
                illegal drugs prior to and during employment. I understand that
                this application is not a contract of employment. My employment
                is contingent upon confirmation of credentials and successful
                completion of drug test or criminal background check. I also
                understand that if hired, regardless of any oral presentations
                to the contrary, the employment relationship between Supreme
                Health Services LLC, and myself is terminable at will, so that
                both the company and I remain free to choose to end out work
                relationship at any time for any or no reason. Any changes in
                this employment relationship must be made in writing. My
                signature below acknowledges that I have read, understood, and
                agreed to the above disclosure. I also understand that due to
                the nature of the business, no amount of work can be guaranteed.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="PRINT NAME"
                name="printName"
                rules={[
                  {
                    required: true,
                    message: "Print Name is required",
                  },
                ]}
              >
                <Input placeholder="Enter your full name" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="DATE"
                name="printNameDate"
                rules={[
                  {
                    required: true,
                    message: "Print Name Date is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="MMM DD, YYYY"
                  // defaultValue={today}
                  // disabled
                />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="SIGNATURE"
                name="signature"
                rules={[
                  {
                    required: true,
                    message: "Signatureis required",
                  },
                ]}
              >
                <Input placeholder="Enter your full name" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="DATE"
                name="signatureDate"
                rules={[
                  {
                    required: true,
                    message: "Signature Date is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="MMM DD, YYYY"
                  // defaultValue={today}
                  // disabled
                />
              </Form.Item>
            </div>
          </div>

          <div className="row my-4">
            <div className="col">
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={form
                    .getFieldsError()
                    .some(({ errors }) => errors.length)}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CaregiverForm;
