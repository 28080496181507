import "./Staffing.css";

import professionalStaff from "../../assets/images/professionals.png";

export default function Staffing() {
  return (
    <div className="container py-5">
      <h1 className="fw-bold">Staffing</h1>
      <div className="row mb-3 align-items-center">
        <div className="col-12 col-md-6">
          <h3 className="mt-2 fw-light">
            Are you looking to hire Healthcare personnels?
          </h3>
          <p className="mb-3">
            Supreme Health Services blends cutting-edge staffing skills and
            technology with individualized client service. The services provided
            by Supreme Health Services range from typical temporary placement to
            collaborating with employers to assist them plan their contingent
            workforce needs. Supreme Health Services strives to help each
            customer achieve their objectives by providing personalized staffing
            solutions while upholding the greatest standards of quality, ethics,
            and honesty.
          </p>
          <p className="mb-3">
            Supreme Health Services relentlessly recruits, evaluates, and tests
            applications to guarantee that our clients only receive competent
            individuals. Supreme Health Services creates a win-win situation for
            corporate clients and employees whose services are given by pairing
            the appropriate individual with the right position.
          </p>
          <h4 className="fw-bold mt-4">What We Do</h4>
          <ul className="list-unstyled mb-4">
            <li>
              <i className="fa-solid fa-circle-check"></i>&nbsp;Superior
              Screening Process
            </li>
            <li>
              <i className="fa-solid fa-circle-check"></i>&nbsp;Competency
            </li>
            <li>
              <i className="fa-solid fa-circle-check"></i>&nbsp;Background
              Checks
            </li>
            <li>
              <i className="fa-solid fa-circle-check"></i>&nbsp;Employee
              Training
            </li>
            <li>
              <i className="fa-solid fa-circle-check"></i>&nbsp;Retention
              Strategies
            </li>
            <li>
              <i className="fa-solid fa-circle-check"></i>&nbsp;Complete
              Staffing Solutions
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-6 d-none d-md-flex justify-content-center align-items-center">
          <img
            className="img-fluid"
            alt="professional-staff"
            src={professionalStaff}
          />
        </div>
      </div>
      <div className="row pt-3 mt-3">
        <h4 className="fw-bold text-center">Staffing Request</h4>
        <p className="text-center">
          Do you require assistance in finding the correct employee(s)? Please
          complete and submit the form below to have one of our staff members
          assist you in starting your search.
        </p>
        <form>
          <div className="row mb-md-3">
            <div className="col-12 col-md 6 mb-3">
              <label htmlFor="firstName" className="form-label">
                First name <span className="importantInput">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="firstName"
                aria-label="First name"
                required
              />
            </div>
            <div className="col-12 col-md 6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Last name <span className="importantInput">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="lastName"
                aria-label="Last name"
                required
              />
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12 col-md 6 mb-3">
              <label htmlFor="title" className="form-label">
                Title <span className="importantInput">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="title"
                aria-label="Title"
                required
              />
            </div>
            <div className="col-12 col-md 6 mb-3">
              <label htmlFor="lastName" className="form-label">
                Company <span className="importantInput">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="lastName"
                aria-label="Last name"
                required
              />
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12 col-md 4 mb-3">
              <label htmlFor="emailAddress" className="form-label">
                Email address <span className="importantInput">*</span>
              </label>
              <input
                type="email"
                className="form-control form-control-lg"
                id="emailAddress"
                aria-label="Email address"
                required
              />
            </div>
            <div className="col-12 col-md 4 mb-3">
              <label htmlFor="phone" className="form-label">
                Phone <span className="importantInput">*</span>
              </label>
              <div className="input-group">
                <input
                  // type="text"
                  type="phone"
                  placeholder="+1"
                  className="form-control form-control-lg"
                  id="phone"
                  aria-label="Phone"
                  data-national_mode="enabled"
                  data-validation="standard"
                  data-country="us"
                  required
                />
                <span className="input-group-text"></span>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <label htmlFor="positionType" className="form-label">
                Position type <span className="importantInput">*</span>
              </label>
              <select
                className="form-select form-select-lg"
                id="positionType"
                aria-label="Position type"
                required
              >
                <option value="">Please select</option>
                <option value="Part time">Part time</option>
                <option value="Temp to hire">Temp to hire</option>
                <option value="Direct hire">Direct hire</option>
              </select>
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12 col-md-3 mb-3">
              <label htmlFor="startDate" className="form-label">
                Preferred <strong>Start</strong> Date & Time{" "}
                <span className="importantInput">*</span>
              </label>
              <input
                type="date"
                id="startDate"
                aria-label="Start date"
                className="form-control form-control-lg"
                required
              />
            </div>
            <div className="col-12 col-md-3 mb-3">
              <label htmlFor="startHour" className="form-label">
                &nbsp;
              </label>
              <select
                className="form-select form-select-lg"
                id="startHour"
                aria-label="Start hour"
                required
              >
                <option value="">Hour</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <label htmlFor="startMinute" className="form-label">
                &nbsp;
              </label>
              <select
                className="form-select form-select-lg"
                id="startMinute"
                aria-label="Start minute"
                required
              >
                <option value="">Minute</option>
                <option value="00">00</option>
                <option value="05">05</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                <option value="55">55</option>
              </select>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <label htmlFor="timeIndicator" className="form-label">
                &nbsp;
              </label>
              <select
                className="form-select form-select-lg"
                id="timeIndicator"
                aria-label="Time indicator"
                required
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12 col-md-3 mb-3">
              <label htmlFor="endDate" className="form-label">
                Preferred <strong>End</strong> Date & Time{" "}
                <span className="importantInput">*</span>
              </label>
              <input
                type="date"
                id="endDate"
                className="form-control form-control-lg"
                aria-label="End date"
                required
              />
            </div>
            <div className="col-12 col-md-3 mb-3">
              <label htmlFor="endHour" className="form-label">
                &nbsp;
              </label>
              <select
                className="form-select form-select-lg"
                id="endHour"
                aria-label="End hour"
                required
              >
                <option value="">Hour</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <label htmlFor="endMinute" className="form-label">
                &nbsp;
              </label>
              <select
                className="form-select form-select-lg"
                id="endMinute"
                aria-label="End minute"
                required
              >
                <option value="">Minute</option>
                <option value="00">00</option>
                <option value="05">05</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                <option value="55">55</option>
              </select>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <label htmlFor="endTimeIndicator" className="form-label">
                &nbsp;
              </label>
              <select
                className="form-select form-select-lg"
                id="endTimeIndicator"
                aria-label="End time indicator"
                required
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12 col-md-4 mb-3">
              <label htmlFor="payRate" className="form-label">
                Proposed Pay Rate <span className="importantInput">*</span>
              </label>
              <input
                type="text"
                id="payRate"
                className="form-control form-control-lg"
                aria-label="Proposed pay rate"
                placeholder="$"
                required
              />
            </div>
            <div className="col-12 col-md-4 mb-3">
              <label htmlFor="staffType" className="form-label">
                Type of Staff needed <span className="importantInput">*</span>
              </label>
              <select
                className="form-select form-select-lg"
                id="staffType"
                aria-label="Type of Staff needed"
                required
              >
                <option value="">Select Staff type</option>
                <option value="Certified Nurse Assistant">
                  Certified Nurse Assistant
                </option>
                <option value="Licensed Practical Nurse">
                  Licensed Practical Nurse
                </option>
                <option value="Registered Nurse">Registered Nurse</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <label htmlFor="staffNumber" className="form-label">
                Number of Staff needed <span className="importantInput">*</span>
              </label>
              <input
                type="number"
                className="form-select form-select-lg"
                id="staffNumber"
                aria-label="Number of Staff Needed"
                placeholder="E.g.: 2"
                required
              />
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12  mb-3">
              <label htmlFor="jobDuties" className="form-label">
                Job duties and responsibilities{" "}
                <span className="importantInput">*</span>
              </label>
              <textarea
                className="form-control form-control-lg"
                id="jobDuties"
                aria-label="Job duties and responsibilities"
                rows="5"
                required
              ></textarea>
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12  mb-3">
              <label htmlFor="addedRequirements" className="form-label">
                Additional requirements
              </label>
              <textarea
                className="form-control form-control-lg"
                id="addedRequirements"
                aria-label="Additional requirements"
                rows="5"
              ></textarea>
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12  mb-3">
              <label htmlFor="message" className="form-label">
                Message <span className="importantInput">*</span>
              </label>
              <textarea
                className="form-control form-control-lg"
                id="message"
                aria-label="Message"
                rows="5"
                required
              ></textarea>
            </div>
          </div>

          <div className="row mb-md-3">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary btn-lg w-100 d-block"
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
