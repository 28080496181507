import Welcome from "../../components/Common/Welcome.js";
import OurSuiteOfServices from "../../components/Common/OurSuiteOfServices.js";
import WhatWeDo from "../../components/Common/WhatWeDo.js";
import ReviewsCarousel from "../../components/Common/ReviewsCarousel.js";

import "./Home.css";

import homeCare from "../../assets/images/homecare-of-america.webp";
import eastDundee from "../../assets/images/best-east-dundee-award.webp";
import Jumbotron from "../../components/Common/Jumbotron.js";

export default function Home() {
  return (
    <section className="home">
      <div className="container-fluid m-0 p-0">
        <Jumbotron />
      </div>
      <div className="container py-5">
        <div className="row my-5 align-items-center">
          <Welcome />
        </div>
        <div className="row my-5 align-items-center">
          <OurSuiteOfServices />
        </div>
        <div className="row my-5 align-items-center">
          <ReviewsCarousel />
        </div>
        <div className="row my-5 justify-content-center align-items-center">
          <WhatWeDo />
        </div>
        <div className="row my-5">
          <h1 className="fw-bold text-center">Awards and Associations</h1>
          <div className="row">
            <div className="col text-center">
              <img
                className="img-fluid"
                src={homeCare}
                alt="HomeCare America Association"
              />
              <div className="col text-center">
                <img
                  className="img-fluid"
                  src={eastDundee}
                  alt="Best East Dundee Hospital Award"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
