import { Link } from "react-router-dom";

import suiteOfServices from "../../assets/images/female-nurse.checkup.png";

export default function OurSuiteOfServices() {
  return (
    <>
      <div className="col-12 col-md-6">
        <h1 className="fw-bold">Our Suite of Services</h1>
        <p>
          We render professional care with compassion and grace within the
          comfort of your own home. Allow us to take care of your unique care
          needs.
        </p>
        <p>
          Every care giving experience is intended to supremely enhance the
          quality of daily living of all our clients.
        </p>
        <div className="row">
          <div className="col-12 col-md-6">
            <ul className="list-unstyled">
              <li className="list-item mb-3">
                <i className="fa-solid fa-house-medical"></i>
                &nbsp;Medical Home Nursing Services
              </li>
              <li className="list-item mb-3">
                <i className="fa-solid fa-user-nurse"></i>
                &nbsp;Non-Medical Services
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <ul className="list-unstyled">
              <li className="list-item mb-3">
                <i className="fa-solid fa-briefcase-medical"></i>
                &nbsp;Comprehensive Medical Care
              </li>
              <li className="list-item mb-3">
                <i className="fa-solid fa-user-doctor"></i>
                &nbsp;Medical Staffing
              </li>
            </ul>
          </div>
        </div>
        <div className="cta-btn">
          <form className="d-flex">
            <Link
              className="btn btn-lg shs-btn px-5"
              type="submit"
              to="/services"
            >
              Learn More
            </Link>
          </form>
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
        <img
          className="img-fluid"
          src={suiteOfServices}
          alt="Suite of sdervices"
        />
      </div>
    </>
  );
}
