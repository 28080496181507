import "./ContactUs.css";

import contactUS from "../../assets/images/contact-us.jpg";

export default function ContactUs() {
  return (
    <div className="container py-5">
      <h1 className="fw-bold">Contact Us</h1>
      <div className="row contact align-items-center">
        {/* <div className="col-12 col-md-6">
          <p className="mb-3">
            How may we help you? Please let us know your concerns, queries,
            clarifications, or feedback by directly calling us or sending us a
            message using the form below.
          </p>
          <form>
            <div className="row mb-md-3">
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="firstName" className="form-label">
                  First name <span className="importantInput">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="firstName"
                  aria-label="First name"
                  required
                />
              </div>
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last name <span className="importantInput">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="lastName"
                  aria-label="Last name"
                  required
                />
              </div>
            </div>

            <div className="row mb-md-3">
              <div className="col-12  mb-3">
                <label htmlFor="address" className="form-label">
                  Address <span className="importantInput">*</span>
                </label>
                <textarea
                  className="form-control form-control-lg"
                  id="address"
                  aria-label="Address"
                  rows="2"
                  required
                ></textarea>
              </div>
            </div>

            <div className="row mb-md-3">
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="emailAddress" className="form-label">
                  Email address <span className="importantInput">*</span>
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="emailAddress"
                  aria-label="Email address"
                  required
                />
              </div>
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone <span className="importantInput">*</span>
                </label>
                <div className="input-group">
                  <input
                    // type="text"
                    type="phone"
                    placeholder="+1"
                    className="form-control form-control-lg"
                    id="phone"
                    aria-label="Phone"
                    data-national_mode="enabled"
                    data-validation="standard"
                    data-country="us"
                    required
                  />
                  <span className="input-group-text"></span>
                </div>
              </div>
            </div>

            <div className="row mb-md-3">
              <div className="col-12  mb-3">
                <label htmlFor="message" className="form-label">
                  Message <span className="importantInput">*</span>
                </label>
                <textarea
                  className="form-control form-control-lg"
                  id="message"
                  aria-label="Message"
                  rows="5"
                  required
                ></textarea>
              </div>
            </div>

            <div className="row mb-md-3">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg w-100 d-block"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div> */}
        <div className="col-12 col-md-6">
          <div className="contact-email">
            <h3>Email:</h3>
            <p>
              <a href="mailto:info@supremehealthservicesllc.com">
                info@supremehealthservicesllc.com
              </a>
            </p>
          </div>
          <div className="contact-phone">
            <h3>Phone:</h3>
            <p>
              <a href="tel:+12244545529">+1 (224) 454-5529</a>
            </p>
          </div>
          <div className="row contact-map">
            <div className="col">
              <h3>Physical Address:</h3>
              <p>
                <a
                  href="https://maps.app.goo.gl/hTJT3VzatQ1eSaWX6"
                  target="_blank"
                  rel="noreferrer"
                >
                  575 N Falls Circle Pingree Grove IL 60140
                </a>
              </p>
              <div className="contact-map">
                <iframe
                  title="Physical Address"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2960.607027133972!2d-88.42847082301085!3d42.09446857121888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f1794811a07f9%3A0xe320ba76256c4671!2sN%20Falls%20Circle%2C%20Pingree%20Grove%20IL!5e0!3m2!1sen!2sch!4v1705079805487!5m2!1sen!2sch"
                  width="100%"
                  // height="100%"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 d-none d-md-flex justify-content-center align-items-center">
          <img src={contactUS} className="img-fluid" alt="Some images" />
        </div>
      </div>
    </div>
  );
}
