import { Link } from "react-router-dom";
import "./Services.css";

import shsllc from "../../assets/images/shsllc.png";
import medicaStaffDialogue from "../../assets/images/medical-staff-dialogue.png";
import femaleNurseCounsel from "../../assets/images/female-nurse-counsel.jpg";
import comprehensiveMedical from "../../assets/images/comprehensive-medical.jpg";
import nonMedical from "../../assets/images/non-medical.jpg";

export default function Services() {
  return (
    <div className="services">
      <div className="container">
        <div className="row my-5 align-items-center">
          <div className="col text-center mb-3">
            <img
              src={shsllc}
              width={300}
              alt="Supreme Health Services LLC"
              className="img-fluid"
            />
            <p>Caring for you where you want and when you want it.</p>
          </div>
        </div>

        <div className="row my-5 align-items-center">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center order-md-2 mb-3">
            <img
              src={medicaStaffDialogue}
              alt="medical-staff-dialogue"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-6 order-md-1 mb-3">
            <h2 className="fw-bold">Medical Staffing</h2>
            <p className="fw-bold">
              We will pursue every avenue to address your staffing needs.
            </p>
            <p>
              Whether you are a hospital, healthcare facility, or a treatment
              center looking for top talents, Supreme Health Services Staffing
              is the bridge that connects you to some of the best of them in the
              industry. With our proven recruitment strategies, we make the
              medical staffing process easier for our clients.
            </p>
            <p>
              We offer extensive opportunities and placements. We also offer
              positions in any setting. We’ll help you bring candidates that can
              help you realize your goals and ensuring a smooth transition for
              clients and applicants. Count on us to hire and provide you with
              competent staff.
            </p>
            <p>
              Should you have care requests not mentioned above, kindly reach us
              today.
            </p>
            <p>
              <Link to="/staffing" className="btn shs-btn">
                Request a Staff
              </Link>
            </p>
          </div>
        </div>

        <div className="row my-5 align-items-center">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center order-md-2 mb-3">
            <img
              src={femaleNurseCounsel}
              alt="female-nurse-counsel"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-6 order-md-1 mb-3">
            <h2 className="fw-bold">Medical Home Nursing</h2>
            <p className="fw-bold">
              Extensive and client-centered care for your loved ones.
            </p>
            <p>
              The assistance of a healthcare professional is essential to
              maintaining or improving your loved one’s health. Here at Inspired
              Grace Healthcare, Inc., we put into consideration each of our
              client’s care preferences and based their care plans on their
              health condition. With this, we can ascertain that they receive
              the healthcare services they need to attain their health goals.
              Our team of registered, well-trained nurses will see to it that
              your loved ones are well-taken care of, leaving their health
              uncompromised.
            </p>
            <p>Our medical home nursing service includes the following:</p>
            <ul className="list-unstyled">
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp; Medication
                Administration and Reminders
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;IV Therapy
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Ventilator
                Care
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Tracheostomy
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Catheter Care
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Diabetes
                Management
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;And More
              </li>
            </ul>
            <p>
              Let our care representatives answer any of your inquiries by
              arranging a personal meeting with us.
            </p>
            <p>
              <Link to="/contact-us" className="btn shs-btn">
                Contact us
              </Link>
            </p>
          </div>
        </div>

        <div className="row my-5 align-items-center">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center order-md-2 mb-3">
            <img
              src={comprehensiveMedical}
              alt="comprehensive-medical"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-6 order-md-1 mb-3">
            <h2 className="fw-bold">Comprehensive Medical Care</h2>
            <p className="fw-bold">We got your every need covered.</p>
            <p>
              Experience holistic services from Supreme Health Services LLC,
              from basic care needs to the complex ones. To render you with the
              right level of care, we involve clients in the decision-making
              process, especially in creating their own care plan.
            </p>
            <p>These are the services we offer:</p>
            <ul className="list-unstyled">
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Emotional and
                Psychological Support
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>
                &nbsp;Transportation and Errands
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>
                &nbsp;Entitlement/Paperwork
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Ambulation
                Assistance
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Medication
                Reminders
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Light
                Housekeeping
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Care
                Coordination
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Care
                Supervision
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Meal
                Preparation
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Care Planning
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Grooming
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Dressing
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Toileting
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Bathing
              </li>
            </ul>
            <p>
              Should you have care requests not mentioned above, kindly reach us
              today.
            </p>
            <p>
              <Link to="/contact-us" className="btn shs-btn">
                Contact us
              </Link>
            </p>
          </div>
        </div>

        <div className="row my-5 align-items-center">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center order-md-2 mb-3">
            <img src={nonMedical} alt="non-medical" className="img-fluid" />
          </div>
          <div className="col-12 col-md-6 order-md-1 mb-3">
            <h2 className="fw-bold">Non-Medical Services</h2>
            <p className="fw-bold">Individualized and flexible care</p>

            <ul className="list-unstyled">
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>
                &nbsp;Live-in/Hourly Home Care
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Companion Care
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Personal Care
              </li>
              <li className="list-item">
                <i className="fa-solid fa-circle-check"></i>&nbsp;Care
                Management
              </li>
            </ul>
            <p>
              At Supreme Health Services LLC, your overall welfare is our top
              priority. We give our clients the opportunity to focus on
              improving their health while we take care of their daily
              non-medical needs. Expect our care professionals to render you
              with services such as the following:
            </p>
            <p>
              Let our care representatives answer any of your inquiries by
              arranging a personal meeting with us.
            </p>
            <p>
              <Link to="/contact-us" className="btn shs-btn">
                Contact us
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
