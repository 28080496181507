import "../../assets/styles/App.css";
import shsllc from "../../assets/images/shsllc.png";

import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="App-footer py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 mb-1 order-md-2">
            <h5>Contact Details</h5>
            <div className="row mb-3">
              <div className="row">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <i className="fa-solid fa-phone"></i>
                </div>
                <div className="col-10">
                  <h5 className="m-0">Phone: </h5>
                  <p className="m-0">
                    <a className="footer-link" href="tel:+12244545529">
                      +1 (224) 454-5529
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="row">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <i className="fa-solid fa-business-time"></i>
                </div>
                <div className="col-10">
                  <h5 className="m-0">Business Hours</h5>
                  <p className="m-0">Mon - Fri: 9:00 AM - 5:00 PM</p>
                  <p className="m-0">Sat - Sun: Closed</p>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="row">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <i className="fa-solid fa-envelope"></i>
                </div>
                <div className="col-10">
                  <h5 className="m-0">Email</h5>
                  <p className="m-0">
                    <a
                      className="footer-link text-center"
                      href="mailto:info@supremehealthservicesllc.com"
                    >
                      &nbsp;info@supremehealthservicesllc.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="row">
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <i className="fa-solid fa-map-location-dot"></i>
                </div>
                <div className="col-10">
                  <h5 className="m-0">Physical Address</h5>
                  <p className="m-0">
                    <a
                      className="footer-link"
                      href="https://maps.app.goo.gl/hTJT3VzatQ1eSaWX6"
                      target="_blank"
                      rel="noreferrer"
                    >
                      575 N Falls Circle Pingree Grove IL 60140
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-3 mx-auto"></div>
          </div>

          <div className="col-12 col-md-4 mb-3 order-md-1">
            <div className="row">
              <h5>Pages</h5>
              <div className="row">
                <div className="col-6">
                  <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                      <Link to="/" className="nav-link p-0 text-body-secondary">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/about-us"
                        className="nav-link p-0 text-body-secondary"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/services"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/staffing"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Staffing
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/consultation"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Consultation
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="nav flex-column">
                    <li className="nav-item mb-2">
                      <a
                        href="http://supremehealthservicesllc.com/blog/"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Blog
                      </a>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/careers"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Careers
                      </Link>
                    </li>
                    <li className="nav-item mb-2">
                      <Link
                        to="/contact-us"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Contact
                      </Link>
                    </li>
                    {/* <li className="nav-item mb-2">
                      <Link
                        to="/resources"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Resources
                      </Link>
                    </li> */}
                    <li className="nav-item mb-2">
                      <Link
                        to="/terms-and-conditions"
                        className="nav-link p-0 text-body-secondary"
                      >
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <h5>Service Area Covered</h5>
              <div className="container">
                <h6>Illinois Counties:</h6>
                <div className="row">
                  <div className="col">
                    <ul className="list-group border-0">
                      <li className="list-group-item border-0 py-0">
                        Cook County
                      </li>
                      <li className="list-group-item border-0 py-0">
                        Dupage County
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="list-group border-0">
                      <li className="list-group-item border-0 py-0">
                        Kane County
                      </li>
                      <li className="list-group-item border-0 py-0">
                        McHenry County
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 mb-3 order-md-0">
            <div className="row">
              <Link to="/" className="w-100 d-flex d-md-block">
                <img
                  className="img-fluid mx-auto"
                  alt="Supreme Health Services LLC"
                  width={200}
                  src={shsllc}
                />
              </Link>
              <p>
                At Supreme Health Services, we provide exceptional care to our
                clients, identifying all physical, psychological and emotional
                needs. Providing supreme health services is our passion.
              </p>
            </div>
            <div className="row">
              <div className="row">
                <p>
                  &copy;&nbsp;{currentYear}&nbsp;Supreme Health Services,
                  LLC.&nbsp;All rights reserved.
                </p>
              </div>
              <div className="row">
                <ul className="list-unstyled d-flex justify-content-center">
                  <li className="mx-3">
                    <a href="./" className="footer-link">
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li className="mx-3">
                    <a href="./" className="footer-link">
                      <i className="fa-brands fa-x"></i>
                    </a>
                  </li>
                  <li className="mx-3">
                    <a href="./" className="footer-link">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
