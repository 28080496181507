import { useState } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Radio } from "antd";

const { RangePicker } = DatePicker;

export default function RnLpnForm() {
  const [form] = Form.useForm();

  const [proCred, setProCred] = useState(1);
  const [questCurrentlyWorking1, setQuestCurrentlyWorking1] = useState(null);
  const [questMayWeContactYou1, setQuestMayWeContactYou1] = useState(null);
  const [questTravelAssignment1, setQuestTravelAssignment1] = useState(null);
  const [questlocalStaffingAgency1, setQuestlocalStaffingAgency1] =
    useState(null);

  const [questCurrentlyWorking2, setQuestCurrentlyWorking2] = useState(null);
  const [questMayWeContactYou2, setQuestMayWeContactYou2] = useState(null);
  const [questTravelAssignment2, setQuestTravelAssignment2] = useState(null);
  const [questlocalStaffingAgency2, setQuestlocalStaffingAgency2] =
    useState(null);

  const [questCurrentlyWorking3, setQuestCurrentlyWorking3] = useState(null);
  const [questMayWeContactYou3, setQuestMayWeContactYou3] = useState(null);
  const [questTravelAssignment3, setQuestTravelAssignment3] = useState(null);
  const [questlocalStaffingAgency3, setQuestlocalStaffingAgency3] =
    useState(null);

  const [questCurrentlyWorking4, setQuestCurrentlyWorking4] = useState(null);
  const [questMayWeContactYou4, setQuestMayWeContactYou4] = useState(null);
  const [questTravelAssignment4, setQuestTravelAssignment4] = useState(null);
  const [questlocalStaffingAgency4, setQuestlocalStaffingAgency4] =
    useState(null);

  // const today = new Date().toISOString().slice(0, 10);
  // const today = new Date().getDate();

  const handleSubmit = (values) => {
    console.log({ values });
  };

  return (
    <>
      <div className="container my-5" id="form">
        <h1 className="text-center my-5 text-decoration-underline">
          RN/LPN EMPLOYMENT APPLICATION
        </h1>
        <div className="p-3 border border-dark mb-3">
          <p>
            <strong>INSTRUCTIONS:</strong>&nbsp;If you need help filling out
            this form or for any phase of the employment process, please notify
            the person who gave you this form and every reasonable effort will
            be made to meet your needs in a reasonable amount of time.
          </p>
          <ul>
            <li>Please read "Applicant Note" below.</li>
            <li>Complete all fields of this application</li>
            <li>Clear fill out the fields of this application</li>
            <li>
              If more space is needed to complete any question, use comment
              section below
            </li>
            <li>Appliation will be valid for 60 days</li>
          </ul>
        </div>
        <div>
          <p>
            <strong>APPLICANT NOTE:</strong>&nbsp;This application form is
            intended for use in evaluating your qualifications for employment
            with Supreme Health Services LLC. This is not an employment
            contract. Please answer all appropriate questions completely and
            accurately. False or misleading statements during the interview and
            on this form are grounds for terminating the application process or,
            if discovered after employment begins, terminating employment. All
            qualified applicants will receive consideration and will be treated throughout
            their employment without regard to race, color, religion, sex,
            national origin, age, disability, or any other protected class
            status under applicable law. Additional testing for the presence of
            illegal drugs in your body may be required prior to employment.
          </p>
        </div>
        <div
          style={{
            height: 10 + "px",
            backgroundColor: "#000000",
            border: "none",
          }}
          className="my-3"
        ></div>
        <Form
          form={form}
          name="rn-lpn-form"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <h3 className="text-decoration-underline mt-5 mb-3">
            PERSONAL INFORMATION
          </h3>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Today's date"
                name="todaysDate"
                rules={[
                  {
                    required: true,
                    message: "Today's date is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="MMM DD, YYYY"
                  // defaultValue={today}
                  // disabled
                />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Position(s) applied for"
                name="psitionsAppliedFor"
                rules={[
                  {
                    required: true,
                    message: "Position(s) applied for is required",
                  },
                ]}
              >
                <Input placeholder="Enter the position(s) you are applying for" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Last name is required",
                  },
                ]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First name is required",
                  },
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Middle name"
                name="middleName"
                rules={[
                  {
                    required: true,
                    message: "Middle name is required",
                  },
                ]}
              >
                <Input placeholder="Middle name" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Date of Birth"
                name="dateOfBirth"
                rules={[
                  {
                    required: true,
                    message: "Date of Birth is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="MMM DD, YYYY"
                  placeholder="Select your date of birth"
                  // defaultValue={today}
                  // disabled
                />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Gender is required",
                  },
                ]}
              >
                <Input placeholder="Gender" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Social Security Number"
                name="ssn"
                rules={[
                  {
                    required: true,
                    message: "Social Security Number is required",
                  },
                ]}
              >
                <Input placeholder="Social Security Number" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Current Address"
                name="currentAddress"
                rules={[
                  {
                    required: true,
                    message: "Current Address is required",
                  },
                ]}
              >
                <Input placeholder="Current Address" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Previous Address"
                name="previousAddress"
                rules={[
                  {
                    required: true,
                    message: "Previous Address is required",
                  },
                ]}
              >
                <Input placeholder="Previous Address" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Home Phone"
                name="homePhone"
                rules={[
                  {
                    required: true,
                    message: "Home Phone is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Home Phone" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Work Phone"
                name="workPhone"
                rules={[
                  {
                    required: true,
                    message: "Work Phone is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Work Phone" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Cell Phone"
                name="cellPhone"
                rules={[
                  {
                    required: true,
                    message: "Cell Phone is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Cell Phone" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Alternate Phone"
                name="alternatePhone"
                rules={[
                  {
                    required: true,
                    message: "Alternate Phone is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Alternate Phone" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Emergency Contact Name 1"
                name="emergencyContactName1"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Name 1 is required",
                  },
                ]}
              >
                <Input placeholder="Emergency Contact Name 1" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Emergency Contact Phone 1"
                name="emergencyContactPhone1"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Phone 1 is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Emergency Contact Phone 1" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Emergency Contact Name 2"
                name="emergencyContactName2"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Name 2 is required",
                  },
                ]}
              >
                <Input placeholder="Emergency Contact Name 2" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Emergency Contact Phone 2"
                name="emergencyContactPhone2"
                rules={[
                  {
                    required: true,
                    message: "Emergency Contact Phone 2 is required",
                    type: "phone",
                  },
                ]}
              >
                <Input placeholder="Emergency Contact Phone 2" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Valid Driver's License Number"
                name="validLicenseDriverNumber"
                rules={[
                  {
                    required: true,
                    message: "Valid Driver's License Number is required",
                  },
                ]}
              >
                <Input placeholder="Valid Driver's License Number" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="State Issued"
                name="stateIssued"
                rules={[
                  {
                    required: true,
                    message: "State Issued is required",
                  },
                ]}
              >
                <Input placeholder="State Issued" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Expiration Date"
                name="expirationDate"
                rules={[
                  {
                    required: true,
                    message: "Expiration Date is required",
                  },
                ]}
              >
                <Input placeholder="Expiration Date" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Make & Model of Vehicle"
                name="makeAndModelOfVehicle"
                rules={[
                  {
                    required: true,
                    message: "Make & Model of Vehicle is required",
                  },
                ]}
              >
                <Input placeholder="Make & Model of Vehicle" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Year of Vehicle"
                name="stateIssued"
                rules={[
                  {
                    required: true,
                    message: "Year of Vehicle is required",
                  },
                ]}
              >
                <Input placeholder="Year of Vehicle" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Auto in Co."
                name="autoInCo"
                rules={[
                  {
                    required: true,
                    message: "Auto in Co. is required",
                  },
                ]}
              >
                <Input placeholder="Auto in Co." />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Policy Number"
                name="policyNumber"
                rules={[
                  {
                    required: true,
                    message: "Policy Number is required",
                  },
                ]}
              >
                <Input placeholder="Policy Number" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="Expiration Date"
                name="expirationDate"
                rules={[
                  {
                    required: true,
                    message: "Expiration Date is required",
                  },
                ]}
              >
                <Input placeholder="Expiration Date" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Have you ever submitted and application here before? Yes / No? If yes, when?"
                name="previousApplication"
                rules={[
                  {
                    required: true,
                    message: "Previous application is required",
                  },
                ]}
              >
                <Input placeholder="Previous application" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="Have you ever been exmployed here before? Yes / No? If yes, when?"
                name="previousEmployment"
                rules={[
                  {
                    required: true,
                    message: "Previous employment field is required",
                  },
                ]}
              >
                <Input placeholder="Previous employment here?" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Education</th>
                    <th scope="col">Name and Location of School</th>
                    <th scope="col">Date of Graduation</th>
                    <th scope="col">Degree/Credentials</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Basic Nursing Education:</th>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="basicNursingSchoolName"
                      >
                        <Input placeholder="Name and Location of School" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="basicNursingSchoolGradDate"
                      >
                        <Input placeholder="Grad. Date" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="basicNursingSchoolDegree"
                      >
                        <Input placeholder="Degree" />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Graduate Nursing Education:</th>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="gradNursingSchoolName"
                      >
                        <Input placeholder="Name and Location of School" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="gradNursingSchoolGradDate"
                      >
                        <Input placeholder="Grad. Date" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="gradNursingSchoolDegree"
                      >
                        <Input placeholder="Degree" />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Certificate/Program/Other:</th>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="CertNursingSchoolName"
                      >
                        <Input placeholder="Name and Location of School" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="certNursingSchoolGradDate"
                      >
                        <Input placeholder="Grad. Date" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                        name="certNursingSchoolDegree"
                      >
                        <Input placeholder="Degree" />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h3 className="text-decoration-underline mt-5 mb-3">
            PROFESSIONAL CREDENTIALS
          </h3>

          <div className="row border border-black py-3">
            <div className="col">
              <div className="row">
                <div className="col">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Select professional type",
                      },
                    ]}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      {/* <div className="pro-checkboxes">
                        <Checkbox.Group
                          options={[
                            { label: "RN", value: "rnLicense" },
                            { label: "LPN", value: "lpnLicense" },
                            { label: "Other", value: "otherLicense" },
                          ]}
                          onChange={(checkedValue) => {
                            console.log("checkbox checked = ", checkedValue);
                          }}
                        />
                      </div> */}
                      <div className="pro-radios">
                        <Radio.Group
                          onChange={(checkedValue) => {
                            console.log(
                              "radio checked",
                              checkedValue.target.value
                            );
                            setProCred(checkedValue.target.value);
                          }}
                          value={proCred}
                        >
                          <Radio value={"rnLicense"}>RN</Radio>
                          <Radio value={"lpnLicense"}>LPN</Radio>
                          <Radio value={"otherLicense"}>Other</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </div>

              <div className="row mx-auto" style={{ width: "400px" }}>
                <div className="col">
                  <Form.Item
                    label="Professional Designation"
                    name="proDesignation"
                    rules={[
                      {
                        required: true,
                        message: "Previous employment field is required",
                      },
                    ]}
                  >
                    <Input placeholder="Professinial Designation" />
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md">
                  <Form.Item
                    label="License Number"
                    name="licenseNumber"
                    rules={[
                      {
                        required: true,
                        message: "License Number is required",
                      },
                    ]}
                  >
                    <Input placeholder="License Number" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md">
                  <Form.Item
                    label="Expiration Date"
                    name="expirationDate"
                    rules={[
                      {
                        required: true,
                        message: "Expiration Date is required",
                      },
                    ]}
                  >
                    <Input placeholder="Expiration Date is required" />
                  </Form.Item>
                </div>
                <p>
                  (Verified and documented from the Department of Professional
                  Regulations and Public Health respectively)
                </p>
              </div>

              <div className="row">
                <div className="col">
                  <div className="text-center">
                    <div>
                      <Checkbox.Group
                        options={[
                          { label: "MED SURG", value: "medSurg" },
                          { label: "Tele/PACU", value: "telePacu" },
                          { label: "TRACH CARE", value: "trachCare" },
                        ]}
                        onChange={(checkedValue) => {
                          console.log("checkbox checked = ", checkedValue);
                        }}
                      />
                    </div>

                    <div>
                      <Checkbox.Group
                        options={[
                          { label: "ICU/CCU", value: "icuCcu" },
                          { label: "RN/Psychiatry", value: "rnPsychiatry" },
                          { label: "VENTILATOR EXP", value: "ventilatorExp" },
                        ]}
                        onChange={(checkedValue) => {
                          console.log("checkbox checked = ", checkedValue);
                        }}
                      />
                    </div>

                    <div>
                      <Checkbox.Group
                        options={[
                          { label: "NICU", value: "nicu" },
                          { label: "CV/ORRN", value: "cvOrrn" },
                          { label: "L&DRN", value: "lDrn" },
                        ]}
                        onChange={(checkedValue) => {
                          console.log("checkbox checked = ", checkedValue);
                        }}
                      />
                    </div>

                    <div>
                      <Checkbox.Group
                        options={[
                          { label: "PCIU", value: "pciu" },
                          { label: "ORRN", value: "orrn" },
                          { label: "ERRN", value: "errn" },
                        ]}
                        onChange={(checkedValue) => {
                          console.log("checkbox checked = ", checkedValue);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 col-md">
                  <Form.Item
                    label="Primary Specialty"
                    name="primarySpecialty"
                    rules={[
                      {
                        required: true,
                        message: "Primary Specialty is required",
                      },
                    ]}
                  >
                    <Input placeholder="Primary Specialty" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md">
                  <Form.Item
                    label="Years of Experience"
                    name="primarySpecialtyYearsofExperience"
                    rules={[
                      {
                        required: true,
                        message: "Years of Experience is required",
                      },
                    ]}
                  >
                    <Input placeholder="Years of Experience is required" />
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md">
                  <Form.Item
                    label="Secondary Specialty"
                    name="secondarySpecialty"
                    rules={[
                      {
                        required: true,
                        message: "Secondary Specialty is required",
                      },
                    ]}
                  >
                    <Input placeholder="Secondary Specialty" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md">
                  <Form.Item
                    label="Years of Experience"
                    name="secondarySpecialtyYearsofExperience"
                    rules={[
                      {
                        required: true,
                        message: "Years of Experience is required",
                      },
                    ]}
                  >
                    <Input placeholder="Years of Experience is required" />
                  </Form.Item>
                </div>
              </div>

              <p>
                Copy of documented statement within the last twekve (12) months
              </p>

              <div className="row">
                <div className="col-12 col-md">
                  <Form.Item
                    label="CPR Course Training completed: Date of Certification"
                    name="secondarySpecialty"
                    rules={[
                      {
                        required: true,
                        message: "CPR Course Training is required",
                      },
                    ]}
                  >
                    <Input placeholder="CPR Course Training" />
                  </Form.Item>
                </div>
                <div className="col-12 col-md">
                  <Form.Item
                    label="Years of Experience"
                    name="secondarySpecialtyYearsofExperience"
                    rules={[
                      {
                        required: true,
                        message: "Years of Experience is required",
                      },
                    ]}
                  >
                    <Input placeholder="Years of Experience is required" />
                  </Form.Item>
                </div>
              </div>

              <p>
                LICENSURE (Submit all licenses currently held, as well as state
                of original license if not currently held. Include photo copies
                of all licenses held)
              </p>

              <div className="row">
                <div className="col-12 col-md">
                  <Form.Item
                    label="State Expiration Date (Original)"
                    name="stateExpDateOriginal"
                    rules={[
                      {
                        required: true,
                        message: "State Expiration Date (Original) is required",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      format="MMM DD, YYYY"
                      placeholder="Please select date"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-md">
                  <Form.Item
                    label="State Expiration Date 2"
                    name="stateExpDate2"
                    rules={[
                      {
                        required: true,
                        message: "State Expiration Date 2 is required",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      format="MMM DD, YYYY"
                      placeholder="Please select date"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-md">
                  <Form.Item
                    label="State Expiration Date 3"
                    name="stateExpDate3"
                    rules={[
                      {
                        required: true,
                        message: "State Expiration Date 3 is required",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      format="MMM DD, YYYY"
                      placeholder="Please select date"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-md">
                  <Form.Item
                    label="State Expiration Date 4"
                    name="stateExpDate4"
                    rules={[
                      {
                        required: true,
                        message: "State Expiration Date 4 is required",
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      format="MMM DD, YYYY"
                      placeholder="Please select date"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <h3 className="text-decoration-underline mt-5 mb-3">
            VERIFICATION OF PREVIOUS EMPLOYMENT
          </h3>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="How did you hear about Supreme Health Services LLC?"
                name="howDidYouHearAboutUs"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input placeholder="How did you hear about Supreme Health Services LLC?" />
              </Form.Item>
            </div>
          </div>

          {/* <div className="previous-employment-1 mt-4 mb-3">
            <hr />

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Are you currently working?"
                  name="currentlyWorking1"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) => {
                      console.log("radio checked", checkedValue.target.value);
                      setQuestCurrentlyWorking1(checkedValue.target.value);
                    }}
                    value={questCurrentlyWorking1}
                  >
                    <Radio value={"currentlyWorking1Yes"}>Yes</Radio>
                    <Radio value={"currentlyWorking1No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="If yes, may we contact you?"
                  name="mayWeContactYou1"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) => {
                      console.log("radio checked", checkedValue.target.value);
                      setQuestMayWeContactYou1(checkedValue.target.value);
                    }}
                    value={questMayWeContactYou1}
                  >
                    <Radio value={"mayWeContactYes1"}>Yes</Radio>
                    <Radio value={"mayWeContactNo1"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="companyName1"
                  label="Company Name"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter Company Name" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="city1"
                  label="City"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="state1"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter State" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="phoneNumber1"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter Phone Number" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="dateEmployedFrom1"
                  label="Date Employed From"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <RangePicker format="MMM DD, YYYY" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="jobTitle1"
                  label="Job Title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter Job Title" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="supervisorsName1"
                  label="Supervisor's Name"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter Supervisor's Name" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="duties1"
                  label="Duties"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter your Duties" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Travel Assignment?"
                  name="travelAssignment1"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) => {
                      console.log("radio checked", checkedValue.target.value);
                      setQuestTravelAssignment1(checkedValue.target.value);
                    }}
                    value={questTravelAssignment1}
                  >
                    <Radio value={"travelAssignmentYes1"}>Yes</Radio>
                    <Radio value={"travelAssignmentNo1"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="Local Staffing Agency?"
                  name="localStaffingAgency1"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) => {
                      console.log("radio checked", checkedValue.target.value);
                      setQuestlocalStaffingAgency1(checkedValue.target.value);
                    }}
                    value={questlocalStaffingAgency1}
                  >
                    <Radio value={"localStaffYes1"}>Yes</Radio>
                    <Radio value={"localStaffNo1"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div> */}

          {/* .previous-employment-1 */}
          <div className="previous-employment-1 mt-4 mb-3">
            <hr />
            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Are you currently working?"
                  name="currentlyWorking1"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestCurrentlyWorking1(checkedValue.target.value)
                    }
                    value={questCurrentlyWorking1}
                  >
                    <Radio value={"currentlyWorking1Yes"}>Yes</Radio>
                    <Radio value={"currentlyWorking1No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="If yes, may we contact you?"
                  name="mayWeContactYou1"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestMayWeContactYou1(checkedValue.target.value)
                    }
                    value={questMayWeContactYou1}
                  >
                    <Radio value={"mayWeContactYes1"}>Yes</Radio>
                    <Radio value={"mayWeContactNo1"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="companyName1"
                  label="Company Name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Company Name" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="city1"
                  label="City"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="state1"
                  label="State"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter State" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="phoneNumber1"
                  label="Phone Number"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Phone Number" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="dateEmployedFrom1"
                  label="Date Employed From"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <RangePicker format="MMM DD, YYYY" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="jobTitle1"
                  label="Job Title"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Job Title" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="supervisorsName1"
                  label="Supervisor's Name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Supervisor's Name" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="duties1"
                  label="Duties"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input.TextArea placeholder="Enter your Duties" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Travel Assignment?"
                  name="travelAssignment1"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestTravelAssignment1(checkedValue.target.value)
                    }
                    value={questTravelAssignment1}
                  >
                    <Radio value={"travelAssignmentYes1"}>Yes</Radio>
                    <Radio value={"travelAssignmentNo1"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="Local Staffing Agency?"
                  name="localStaffingAgency1"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestlocalStaffingAgency1(checkedValue.target.value)
                    }
                    value={questlocalStaffingAgency1}
                  >
                    <Radio value={"localStaffYes1"}>Yes</Radio>
                    <Radio value={"localStaffNo1"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>

          {/* .previous-employment-2 */}
          <div className="previous-employment-2 mt-4 mb-3">
            <hr />
            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Are you currently working?"
                  name="currentlyWorking2"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestCurrentlyWorking2(checkedValue.target.value)
                    }
                    value={questCurrentlyWorking2}
                  >
                    <Radio value={"currentlyWorking2Yes"}>Yes</Radio>
                    <Radio value={"currentlyWorking2No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="If yes, may we contact you?"
                  name="mayWeContactYou2"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestMayWeContactYou2(checkedValue.target.value)
                    }
                    value={questMayWeContactYou2}
                  >
                    <Radio value={"mayWeContactYes2"}>Yes</Radio>
                    <Radio value={"mayWeContactNo2"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="companyName2"
                  label="Company Name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Company Name" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="city2"
                  label="City"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="state2"
                  label="State"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter State" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="phoneNumber2"
                  label="Phone Number"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Phone Number" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="dateEmployedFrom2"
                  label="Date Employed From"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <RangePicker format="MMM DD, YYYY" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="jobTitle2"
                  label="Job Title"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Job Title" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="supervisorsName2"
                  label="Supervisor's Name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Supervisor's Name" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="duties2"
                  label="Duties"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input.TextArea placeholder="Enter your Duties" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Travel Assignment?"
                  name="travelAssignment2"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestTravelAssignment2(checkedValue.target.value)
                    }
                    value={questTravelAssignment2}
                  >
                    <Radio value={"travelAssignmentYes2"}>Yes</Radio>
                    <Radio value={"travelAssignmentNo2"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="Local Staffing Agency?"
                  name="localStaffingAgency2"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestlocalStaffingAgency2(checkedValue.target.value)
                    }
                    value={questlocalStaffingAgency2}
                  >
                    <Radio value={"localStaffYes2"}>Yes</Radio>
                    <Radio value={"localStaffNo2"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>

          {/* .previous-employment-3 */}
          <div className="previous-employment-3 mt-4 mb-3">
            <hr />
            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Are you currently working?"
                  name="currentlyWorking3"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestCurrentlyWorking3(checkedValue.target.value)
                    }
                    value={questCurrentlyWorking3}
                  >
                    <Radio value={"currentlyWorking3Yes"}>Yes</Radio>
                    <Radio value={"currentlyWorking3No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="If yes, may we contact you?"
                  name="mayWeContactYou3"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestMayWeContactYou3(checkedValue.target.value)
                    }
                    value={questMayWeContactYou3}
                  >
                    <Radio value={"mayWeContactYes3"}>Yes</Radio>
                    <Radio value={"mayWeContactNo3"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="companyName3"
                  label="Company Name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Company Name" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="city3"
                  label="City"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="state3"
                  label="State"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter State" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="phoneNumber3"
                  label="Phone Number"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Phone Number" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="dateEmployedFrom3"
                  label="Date Employed From"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <RangePicker format="MMM DD, YYYY" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="jobTitle3"
                  label="Job Title"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Job Title" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="supervisorsName3"
                  label="Supervisor's Name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Supervisor's Name" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="duties3"
                  label="Duties"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input.TextArea placeholder="Enter your Duties" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Travel Assignment?"
                  name="travelAssignment3"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestTravelAssignment3(checkedValue.target.value)
                    }
                    value={questTravelAssignment3}
                  >
                    <Radio value={"travelAssignmentYes3"}>Yes</Radio>
                    <Radio value={"travelAssignmentNo3"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="Local Staffing Agency?"
                  name="localStaffingAgency3"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestlocalStaffingAgency3(checkedValue.target.value)
                    }
                    value={questlocalStaffingAgency3}
                  >
                    <Radio value={"localStaffYes3"}>Yes</Radio>
                    <Radio value={"localStaffNo3"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>

          {/* .previous-employment-4 */}
          <div className="previous-employment-4 mt-4 mb-3">
            <hr />
            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Are you currently working?"
                  name="currentlyWorking4"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestCurrentlyWorking4(checkedValue.target.value)
                    }
                    value={questCurrentlyWorking4}
                  >
                    <Radio value={"currentlyWorking4Yes"}>Yes</Radio>
                    <Radio value={"currentlyWorking4No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="If yes, may we contact you?"
                  name="mayWeContactYou4"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestMayWeContactYou4(checkedValue.target.value)
                    }
                    value={questMayWeContactYou4}
                  >
                    <Radio value={"mayWeContactYes4"}>Yes</Radio>
                    <Radio value={"mayWeContactNo4"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="companyName4"
                  label="Company Name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Company Name" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="city4"
                  label="City"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter City" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="state4"
                  label="State"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter State" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="phoneNumber4"
                  label="Phone Number"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Phone Number" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="dateEmployedFrom4"
                  label="Date Employed From"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <RangePicker format="MMM DD, YYYY" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="jobTitle4"
                  label="Job Title"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Job Title" />
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  name="supervisorsName4"
                  label="Supervisor's Name"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input placeholder="Enter Supervisor's Name" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  name="duties4"
                  label="Duties"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Input.TextArea placeholder="Enter your Duties" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md">
                <Form.Item
                  label="Travel Assignment?"
                  name="travelAssignment4"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestTravelAssignment4(checkedValue.target.value)
                    }
                    value={questTravelAssignment4}
                  >
                    <Radio value={"travelAssignmentYes4"}>Yes</Radio>
                    <Radio value={"travelAssignmentNo4"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md">
                <Form.Item
                  label="Local Staffing Agency?"
                  name="localStaffingAgency4"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Radio.Group
                    onChange={(checkedValue) =>
                      setQuestlocalStaffingAgency4(checkedValue.target.value)
                    }
                    value={questlocalStaffingAgency4}
                  >
                    <Radio value={"localStaffYes4"}>Yes</Radio>
                    <Radio value={"localStaffNo4"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          </div>

          <h3 className="text-decoration-underline mt-5 mb-3">
            EMPLOYMENT ACKNOWLEDGEMENT
          </h3>

          <div className="row my-3">
            <div className="col">
              <p>
                I acknowledge that any false statements or material omissions
                made as part of this application will disqualify me from further
                consideration for employment and, if discovered later, will be
                grounds for discharge. I also understand that any offer of
                employment is contingent upon the results of a pre-employment
                medical examination, drug screen, criminal background check, and
                reference check. I authorize my former employers to release all
                information concerning my employment.
              </p>
              <p>
                I further authorize the release of any such information during
                or after my employment, without prior notification. This
                authorization releases the aforesaid parties and Supreme Health
                Services LLC from any liability for the collection and reporting
                of this information. Supreme Health Services LLC does not
                discriminate in hiring or employment on the basis of sex, color,
                marital status, religion, sexual orientation, national origin,
                age, disability, military status, or any other protected
                category. No question on this application is intended to secure
                ion to be used for such discrimination.
              </p>
              <p>
                I understand that if I am employed by Supreme Health Services
                LLC, my employment is "at will" and maybe terminated by me or
                Supreme Health Services LLC at any time with or without cause,
                for any reason. No one other than the President of Supreme
                Health Services LLC has the authority to enter into an agreement
                contrary to the foregoing and any such agreement must be in
                writing and signed by both the president and Supreme Health
                Services LLC.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="PRINT NAME"
                name="printName"
                rules={[
                  {
                    required: true,
                    message: "Print Name is required",
                  },
                ]}
              >
                <Input placeholder="Enter your full name" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="DATE"
                name="printNameDate"
                rules={[
                  {
                    required: true,
                    message: "Print Name Date is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="MMM DD, YYYY"
                  // defaultValue={today}
                  // disabled
                />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md">
              <Form.Item
                label="SIGNATURE"
                name="signature"
                rules={[
                  {
                    required: true,
                    message: "Signatureis required",
                  },
                ]}
              >
                <Input placeholder="Enter your full name" />
              </Form.Item>
            </div>
            <div className="col-12 col-md">
              <Form.Item
                label="DATE"
                name="signatureDate"
                rules={[
                  {
                    required: true,
                    message: "Signature Date is required",
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  format="MMM DD, YYYY"
                  // defaultValue={today}
                  // disabled
                />
              </Form.Item>
            </div>
          </div>

          <div className="row my-4">
            <div className="col">
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={form
                    .getFieldsError()
                    .some(({ errors }) => errors.length)}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
