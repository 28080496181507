import "./Consultation.css";

import consultation from "../../assets/images/consultation.jpg";

export default function Consultation() {
  return (
    <div className="container py-5">
      <h1 className="fw-bold">Consultation</h1>
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <p>
            Free Consultation Healthcare on Homecare Services.
            <br className="mb-2" />
            Please fill out the form below and we will reach out to you shortly.
          </p>
          <form>
            <div className="row mb-md-3">
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="firstName" className="form-label">
                  First name <span className="importantInput">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="firstName"
                  aria-label="First name"
                  required
                />
              </div>
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last name <span className="importantInput">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="lastName"
                  aria-label="Last name"
                  required
                />
              </div>
            </div>

            <div className="row mb-md-3">
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="emailAddress" className="form-label">
                  Email address <span className="importantInput">*</span>
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="emailAddress"
                  aria-label="Email address"
                  required
                />
              </div>
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone <span className="importantInput">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="tel"
                    placeholder="+1"
                    className="form-control form-control-lg"
                    id="phone"
                    aria-label="Phone"
                    data-national_mode="enabled"
                    data-validation="standard"
                    data-country="us"
                    autoComplete="tel"
                    required
                  />
                  <span className="input-group-text"></span>
                </div>
              </div>
            </div>

            <div className="row mb-md-3">
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="careLocation" className="form-label">
                  I am looking for care in{" "}
                  <span className="importantInput">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="careLocation"
                  aria-label="I am looking for care in"
                  placeholder="Enter a location here"
                  required
                />
              </div>
              <div className="col-12 col-md 6 mb-3">
                <label htmlFor="lastName" className="form-label">
                  How did you hear about us?{" "}
                  <span className="importantInput">*</span>
                </label>
                <select
                  className="form-select form-select-lg"
                  id="hearAboutUs"
                  aria-label="How did you hear about us?"
                  required
                >
                  <option value="">Please select</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Social Worker">Social Worker</option>
                  <option value="Hospital">Hospital</option>
                  <option value="Rehab Facility">Rehab Facility</option>
                  <option value="Senior Community">Senior Community</option>
                  <option value="Referred by a friend or family member">
                    Referred by a friend or family member
                  </option>
                  <option value="Google Search">Google Search</option>
                  <option value="Bing Search">Bing Search</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>

            <div className="row mb-md-3">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg w-100 d-block"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 col-md-6 d-none d-md-flex justify-content-center align-items-center">
          <img className="img-fluid" src={consultation} alt="consultation" />
        </div>
      </div>
    </div>
  );
}
