import customerReview from "../../assets/images/medical-staff-discussion.png";

export default function WhatWeDo() {
  return (
    <>
      <div className="col-12 col-md-6">
        <div className="card w-100 mb-3">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-2 d-flex justify-content-center align-items-center">
                <div className="icon-circle">
                  <i className="fa-solid fa-file-signature"></i>
                </div>
              </div>
              <div className="col-10">
                <h5 className="card-title">We accept insurance!</h5>
                <p className="card-text">
                  Check out if the services you are availing are covered by your
                  insurance plans.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 mb-3">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-2 d-flex justify-content-center align-items-center">
                <div className="icon-circle">
                  <i className="fa-solid fa-map-location-dot"></i>
                </div>
              </div>
              <div className="col-10">
                <h5 className="card-title">Service area covered</h5>
                <p className="card-text">
                  -&nbsp;Cook County
                  <br />
                  -&nbsp;Dupage County
                  <br />
                  -&nbsp;Mc Henry County
                  <br />
                  -&nbsp;Kane County
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 mb-3">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-2 d-flex justify-content-center align-items-center">
                <div className="icon-circle">
                  <i className="fa-solid fa-share-nodes"></i>
                </div>
              </div>
              <div className="col-10">
                <h5 className="card-title">We appreciate referrals</h5>
                <p className="card-text">
                  Help someone in need by referring them to us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
        <img className="img-fluid" src={customerReview} alt="Customer review" />
      </div>
    </>
  );
}
