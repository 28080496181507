import customerReviews from "../../assets/data/customer-reviews.json";

export default function ReviewsCarousel() {
  return (
    <section>
      <h1 className="fw-bold text-center">Customer Reviews</h1>
      <div id="carouselReviewIndicators" className="carousel slide">
        <div className="carousel-indicators review-indicators">
          <button
            type="button"
            data-bs-target="#carouselReviewIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          {customerReviews.map((review) => (
            <button
              key={review.id}
              type="button"
              data-bs-target="#carouselReviewIndicators"
              data-bs-slide-to={review.id}
              aria-label={"Slide " + (review.id + 1)}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="card mx-auto" style={{ width: "80%" }}>
              <div className="card-header">
                <div className="card-text">
                  <h4 className="p-0 m-0">James Maddison</h4>
                  <ul className="d-flex p-0 m-0 list-unstyled">
                    <li>
                      <i className="review fa-solid fa-star"></i>
                    </li>
                    <li>
                      <i className="review fa-solid fa-star"></i>
                    </li>
                    <li>
                      <i className="review fa-solid fa-star"></i>
                    </li>
                    <li>
                      <i className="review fa-solid fa-star"></i>
                    </li>
                    <li>
                      <i className="review fa-solid fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <blockquote className="blockquote mb-0">
                  <p className="card-text">
                    The service I enjoyed at Supreme Health Services LLC is just
                    top-notch. Pickup was fast and easy and I didn't even have
                    to stress at all. Also, they have commendable service
                    professionals and health workers.
                  </p>
                  <footer className="blockquote-footer">
                    <cite title="Source Title">Altanta, Georgia.</cite>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
          {customerReviews.map((review) => (
            <div key={review.id} className="carousel-item">
              <div className="card mx-auto" style={{ width: "80%" }}>
                <div className="card-header">
                  <div className="card-text">
                    <h4 className="p-0 m-0">{review.name}</h4>
                    <ul className="d-flex p-0 m-0 list-unstyled">
                      {Array.from({ length: review.starRating }, (_, index) => (
                        <li key={index}>
                          <i className="review fa-solid fa-star"></i>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <blockquote className="blockquote mb-0">
                    <p className="card-text">{review.comment}</p>
                    <footer className="blockquote-footer">
                      <cite title="Source Title">{review.location}</cite>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          ))}
          ;
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselReviewIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true">
            <i className="chevron fa-solid fa-chevron-left"></i>
          </span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselReviewIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true">
            <i className="chevron fa-solid fa-chevron-right"></i>
          </span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  );
}
