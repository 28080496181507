import professionals from "../../assets/images/professionals.png";
import nurseWomanCare from "../../assets/images/nurse-woman-care.jpg";
import homecare1 from "../../assets/images/homecare-1.jpg";

export default function Jumbotron() {
  return (
    <>
      <div
        id="welcomeSlider"
        className="carousel carousel-fade"
        data-bs-ride="carousel"
        style={{ height: "85vh" }}
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#welcomeSlider"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#welcomeSlider"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#welcomeSlider"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner d-flex align-items-center h-100">
          <div className="carousel-item active">
            <div className="container py-5">
              <div className="row">
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                  <div className="row text-center text-md-start">
                    <h1 className="fw-bold">Home Care Services</h1>
                    <h4 className="fw-light">
                      that are tailored to meet your needs.
                    </h4>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <ul className="list-unstyled">
                          <li className="list-item">
                            <i className="fa-solid fa-house-medical"></i>
                            &nbsp;Medical Home Nursing Services
                          </li>
                          <li className="list-item">
                            <i className="fa-solid fa-user-nurse"></i>
                            &nbsp;Non-Medical Services
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul className="list-unstyled">
                          <li className="list-item">
                            <i className="fa-solid fa-briefcase-medical"></i>
                            &nbsp;Comprehensive Medical Care
                          </li>
                          <li className="list-item">
                            <i className="fa-solid fa-user-doctor"></i>
                            &nbsp;Medical Staffing
                          </li>
                        </ul>
                      </div>
                      <div className="cta-btn">
                        <form className="d-flex">
                          <a
                            className="btn btn-lg shs-btn px-5"
                            href="tel:+12244545529"
                          >
                            Call&nbsp;+1 (224) 454-5529
                          </a>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid d-block w-100"
                    src={nurseWomanCare}
                    alt="Nurse caring for woman"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container d-flex align-items-center py-5">
              <div className="row">
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                  <div className="row text-center text-md-start">
                    <h1 className="fw-bold">Quality Home Care Services</h1>
                    <h4 className="fw-light">
                      with compassionate professionals.
                    </h4>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <ul className="list-unstyled">
                          <li className="list-item">
                            <i className="fa-solid fa-house-medical"></i>
                            &nbsp;Medical Home Nursing Services
                          </li>
                          <li className="list-item">
                            <i className="fa-solid fa-user-nurse"></i>
                            &nbsp;Non-Medical Services
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul className="list-unstyled">
                          <li className="list-item">
                            <i className="fa-solid fa-briefcase-medical"></i>
                            &nbsp;Comprehensive Medical Care
                          </li>
                          <li className="list-item">
                            <i className="fa-solid fa-user-doctor"></i>
                            &nbsp;Medical Staffing
                          </li>
                        </ul>
                      </div>
                      <div className="cta-btn">
                        <form className="d-flex">
                          <a
                            className="btn btn-lg shs-btn px-5"
                            href="tel:+12244545529"
                          >
                            Call&nbsp;+1 (224) 454-5529
                          </a>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid d-block w-100"
                    src={homecare1}
                    alt="Doctor and patient"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container py-5">
              <div className="row">
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                  <div className="row text-center text-md-start">
                    <h1 className="fw-bold">
                      Hire Competent Health Professionals
                    </h1>
                    <h4 className="fw-light">
                      that are dedicated to your health goals.
                    </h4>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <ul className="list-unstyled">
                          <li className="list-item">
                            <i className="fa-solid fa-house-medical"></i>
                            &nbsp;Medical Home Nursing Services
                          </li>
                          <li className="list-item">
                            <i className="fa-solid fa-user-nurse"></i>
                            &nbsp;Non-Medical Services
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul className="list-unstyled">
                          <li className="list-item">
                            <i className="fa-solid fa-briefcase-medical"></i>
                            &nbsp;Comprehensive Medical Care
                          </li>
                          <li className="list-item">
                            <i className="fa-solid fa-user-doctor"></i>
                            &nbsp;Medical Staffing
                          </li>
                        </ul>
                      </div>
                      <div className="cta-btn">
                        <form className="d-flex">
                          <a
                            className="btn btn-lg shs-btn px-5"
                            href="tel:+12244545529"
                          >
                            Call&nbsp;+1 (224) 454-5529
                          </a>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid d-block w-100"
                    src={professionals}
                    alt="Professionals"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
